import jwtDecode from 'jwt-decode';

import type {
  AuthServiceProps,
  DecodedJWT,
  TokenRequestBody,
  ApplicationPermissionsType,
  UserAuthorizationDataType,
} from '../shared/types';

import { fetchJson } from '../../../api/utils/fetchJson';
import { config } from '../../../common/config';
import { deriveNameFromEmail, toUrlEncoded } from '../shared/utils';

enum LocalStorageKey {
  USER = 'user',
  AUTH = 'auth',
  PRE_AUTH_URI = 'preAuthUri',
  AUTH_DATE = 'authDate',
  USER_PERMISSIONS = 'userPermissions',
}

enum AuthGrantType {
  AUTHORIZATION_CODE = 'authorization_code',
  REFRESH_TOKEN = 'refresh_token',
}

export class AuthService {
  private static instance: AuthService | undefined;
  private readonly props: AuthServiceProps;
  private errorHandler: (error: Error | unknown) => void;

  private constructor(props: AuthServiceProps) {
    this.props = props;
    this.errorHandler = () => undefined;
  }

  static createInstance(props: AuthServiceProps): AuthService {
    if (AuthService.instance) throw new Error('AuthService already created');
    AuthService.instance = new AuthService(props);
    return AuthService.instance;
  }

  static getInstance(): AuthService {
    if (!AuthService.instance) throw new Error('AuthService not created yet');
    return AuthService.instance;
  }

  /** Preparation and code parsing */
  async onApplicationStart(): Promise<void> {
    const code = this.getCodeFromLocation(window.location);
    if (!code) return;

    try {
      const token = await this.fetchToken(code);
      const user = this.setDecodedUserFromToken(token);
      await this.getUserPermissions(user);
      this.setAuthDate();
      this.restoreUri();
    } catch (error: unknown) {
      this.removeItem(LocalStorageKey.AUTH);
      this.removeCodeFromLocation();
      this.errorHandler(error);
    }
  }

  setErrorHandler(func: (error: Error | unknown) => void): void {
    this.errorHandler = func;
  }

  isAuthenticated(): boolean {
    const token = this.getItem(LocalStorageKey.AUTH, '');
    return Boolean(token);
  }

  /** Clean user data and redirect user to OKTA OAuth form */
  reAuthorize(): void {
    this.removeItem(LocalStorageKey.AUTH);
    this.authorize();
  }

  /** This will do a full page reload and to the OAuth2 provider's login page and then redirect back to redirectUri */
  authorize(): boolean {
    const { clientId, provider, authorizeEndpoint, redirectUri, scopes, audience, extraParams } = this.props;

    this.setItem(LocalStorageKey.PRE_AUTH_URI, location.href);

    const query: Record<string, string> = {
      clientId,
      scope: scopes.join(' '),
      responseType: 'code',
      ...(audience && { audience }),
      ...extraParams,
    };
    if (redirectUri) {
      query.redirectUri = redirectUri;
    }

    // responds with a 302 redirect
    const url = `${authorizeEndpoint || `${provider}`}?${toUrlEncoded(query)}`;
    window.location.replace(url);

    return true;
  }

  /** This happens after a full page reload. Read the code from localstorage */
  async fetchToken(code: string, isRefresh = false): Promise<string> {
    const { clientId, clientSecret, provider, tokenEndpoint, redirectUri } = this.props;
    const grantType = AuthGrantType.AUTHORIZATION_CODE;

    let payload: TokenRequestBody = {
      clientId,
      ...(clientSecret ? { clientSecret } : {}),
      redirectUri,
      grantType,
    };
    if (isRefresh) {
      payload = {
        ...payload,
        grantType: AuthGrantType.REFRESH_TOKEN,
        refresh_token: code,
      };
    } else {
      payload = {
        ...payload,
        code,
      };
    }

    try {
      const response = await fetch(`${tokenEndpoint || `${provider}/token`}`, {
        method: 'POST',
        body: toUrlEncoded(payload),
      });

      const jwtToken = (await response.json()) as string;
      if (response.status === 200) {
        this.setAuthToken(jwtToken);
        return Promise.resolve(jwtToken);
      }
      return Promise.reject(jwtToken);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async fetchUserAuthorization(user: string): Promise<UserAuthorizationDataType> {
    const url = new URL(`${config.API_URL}/ams-proxy/get-user-authorization`);
    const requestBody = {
      identifierTypeKey: 'USERNAME',
      identifierTypeValue: `cai_okta_preview:${user}`,
    };
    return fetchJson(url, 'POST', requestBody);
  }

  setDecodedUserFromToken(token: string): string {
    const decoded = jwtDecode<DecodedJWT>(token);
    const { firstName, secondName, userId } = deriveNameFromEmail(decoded.user);
    this.setItem(LocalStorageKey.USER, { firstName, secondName, userId, user: decoded.user });
    return decoded.user;
  }

  setAuthDate(): void {
    this.setItem(LocalStorageKey.AUTH_DATE, new Date().toISOString());
  }

  logout(defaultRoute?: string, shouldEndSession = false): boolean {
    this.removeItem(LocalStorageKey.AUTH);

    if (shouldEndSession) {
      const { clientId, provider, logoutEndpoint, redirectUri = '' } = this.props;
      const query: Record<string, string> = {
        client_id: clientId,
        post_logout_redirect_uri: redirectUri,
      };
      const url = `${logoutEndpoint || `${provider}/logout`}?${toUrlEncoded(query)}`;
      window.location.replace(url);
      return true;
    }

    if (defaultRoute) {
      window.location.replace(defaultRoute);
    } else {
      window.location.reload();
    }
    return true;
  }

  setAuthToken(jwtToken: string): void {
    window.localStorage.setItem(LocalStorageKey.AUTH, jwtToken);
  }

  setAuthPermissions(role: ApplicationPermissionsType[]): void {
    const filteredPermissions = role.find((permission) => permission.appName === 'QET');
    if (filteredPermissions) {
      this.setItem(LocalStorageKey.USER_PERMISSIONS, filteredPermissions.additionalInformation);
    }
  }

  setItem(key: string, obj: unknown): void {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }

  getItem(key: string, defValue = ''): string {
    return window.localStorage.getItem(key) || defValue;
  }

  removeItem(key: string): void {
    window.localStorage.removeItem(key);
  }

  restoreUri(): void {
    const uri = JSON.parse(this.getItem(LocalStorageKey.PRE_AUTH_URI)) as string;
    this.removeItem(LocalStorageKey.PRE_AUTH_URI);

    if (uri !== null) window.location.replace(uri);
    this.removeCodeFromLocation();
  }

  getCodeFromLocation(location: Location): string | null {
    const split = location.toString().split('?');
    if (split.length < 2) {
      return null;
    }

    const pairs = split[1].split('&');
    for (const pair of pairs) {
      const [key, value] = pair.split('=');
      if (key === 'code') {
        return decodeURIComponent(value || '');
      }
    }

    return null;
  }

  removeCodeFromLocation(): void {
    const [base, search] = window.location.href.split('?');
    if (!search) {
      return;
    }

    const newSearch = search
      .split('&')
      .map((param) => param.split('='))
      .filter(([key]) => key !== 'code')
      .map((keyAndVal) => keyAndVal.join('='))
      .join('&');

    window.history.replaceState(window.history.state, 'null', base + (newSearch.length ? `?${newSearch}` : ''));
  }

  async getUserPermissions(user: string): Promise<void> {
    if (user) {
      const { data } = await this.fetchUserAuthorization(user);
      const permissions = data.personByIdentifier?.applicationAccess?.applicationPermissions;
      if (permissions) {
        this.setAuthPermissions(permissions);
      }
    }
  }
}
