import { useState } from 'react';

import type { CreateFormType } from '../CreateFormTypeForm/CreateFormTypeForm';
import type { ReactElement } from 'react';

import { useCreateFormTypeMutation } from '../../../../api/hooks/useCreateFormTypeMutation';
import { getPermission, PermissionKeys } from '../../../../common/utils/permissions';
import { Modal } from '../../../../components/Modal/Modal';
import { CreateFormTypeForm } from '../CreateFormTypeForm/CreateFormTypeForm';

export const CreateFormTypeModalWidget = (): ReactElement => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { mutate } = useCreateFormTypeMutation({ onClose: () => setIsCreateModalOpen(false) });
  const permission: string = getPermission();

  return (
    <>
      {permission === PermissionKeys.QET_ADMIN && (
        <button className="qe-btn" data-testid="create-new-form-btn" onClick={() => setIsCreateModalOpen(true)}>
          Create New Form
        </button>
      )}
      <Modal modalTitle="Create New Form Type" isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)}>
        <CreateFormTypeForm
          onClose={() => setIsCreateModalOpen(false)}
          onSubmit={(v: CreateFormType) => {
            mutate(v);
            setIsCreateModalOpen(false);
          }}
        />
      </Modal>
    </>
  );
};
