type Permission = {
  name: string;
  active: boolean;
};

type PermissionLevels = {
  [key: string]: number;
};

const USER_PERMISSIONS = 'userPermissions';

export const PermissionKeys = {
  QET_ADMIN: 'qet_admin',
  QET_BASIC: 'qet_basic',
  QET_READ_ONLY: 'qet_read_only',
};

const permissionLevels: PermissionLevels = {
  [PermissionKeys.QET_ADMIN]: 1,
  [PermissionKeys.QET_BASIC]: 2,
  [PermissionKeys.QET_READ_ONLY]: 3,
};

const parseItem = (key: string): Permission[] => {
  const item = window.localStorage.getItem(key);
  if (item === null) {
    return [];
  }

  try {
    return JSON.parse(item) as Permission[];
  } catch (error) {
    return [];
  }
};

export const getPermission = (): string => {
  const permissions = parseItem(USER_PERMISSIONS);

  if (permissions.length === 0) {
    return 'NONE';
  }

  const activePermissions = permissions.filter((permission: Permission) => permission.active);

  if (activePermissions.length === 0) {
    return 'NONE';
  }

  activePermissions.sort((a: Permission, b: Permission) => {
    const levelA = permissionLevels[a.name.toLowerCase()];
    const levelB = permissionLevels[b.name.toLowerCase()];
    return levelA - levelB;
  });

  const highestPermission = activePermissions[0].name.toLowerCase();

  return highestPermission;
};
