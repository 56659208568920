import { EditIcon, EyeIcon, FileTextIcon, TrashIcon } from '@prism2/icons-react/prism';
import { Link, useParams } from 'react-router-dom';

import type { Form } from '../../../api/queries/forms/forms.types';
import type { CellContext, ColumnDef } from '@tanstack/react-table';
import type { ReactElement } from 'react';

import { getPermission, PermissionKeys } from '../../../common/utils/permissions';
import { type FormPageURLParams, getNavigationLink } from '../../../modules/navigation/utils/getNavigationLink';

type FormColumnsParams = Readonly<{
  openFormPreview: (form: Form) => void;
  openDeleteFormModal: (form: Form) => void;
  openCopyFormModal: (form: Form) => void;
}>;

const permission: string = getPermission();

const renderCellToFormLink = (info: CellContext<Form, unknown>): ReactElement => {
  const { form } = useParams() as FormPageURLParams;
  const { isDraft, questionSet } = info.row.original;
  const formId = questionSet?.guid ?? '';

  return isDraft ? (
    <span className="decoration-2 cursor-pointer text-primary-dark">
      <Link to={getNavigationLink('QUESTIONS', { form, formId })}>{info.getValue() as string}</Link>
    </span>
  ) : (
    <>{info.getValue() as string}</>
  );
};

const renderCellOfActionBtns = (
  info: CellContext<Form, unknown>,
  openFormPreview: (form: Form) => void,
  openDeleteFormModal: (form: Form) => void,
  openCopyFormModal: (form: Form) => void,
): ReactElement => {
  const { form } = useParams() as FormPageURLParams;
  const { isDraft, questionSet } = info.row.original;
  const formId = questionSet?.guid ?? '';

  return (
    <div className="w-full flex flex-wrap justify-center gap-1">
      {isDraft && [PermissionKeys.QET_ADMIN, PermissionKeys.QET_BASIC].includes(permission) && (
        <Link to={getNavigationLink('QUESTIONS', { form, formId })}>
          <button data-testid="edit-form-btn" className="qe-btn w-8">
            <EditIcon />
          </button>
        </Link>
      )}
      <button data-testid="view-form-btn" className="qe-btn w-8" onClick={() => openFormPreview(info.row.original)}>
        <EyeIcon />
      </button>
      {permission === PermissionKeys.QET_ADMIN && (
        <button
          data-testid="copy-form-btn"
          className="qe-btn  w-8"
          onClick={() => {
            openCopyFormModal(info.row.original);
          }}
        >
          <FileTextIcon />
        </button>
      )}
      {isDraft && permission === PermissionKeys.QET_ADMIN && (
        <button data-testid="remove-form-btn" className="qe-btn w-8" onClick={() => openDeleteFormModal(info.row.original)}>
          <TrashIcon />
        </button>
      )}
    </div>
  );
};

export const formColumns = ({ openFormPreview, openDeleteFormModal, openCopyFormModal }: FormColumnsParams): ColumnDef<Form>[] => {
  return [
    {
      accessorKey: 'version',
      header: 'Version',
      cell: (info) => renderCellToFormLink(info),
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 150,
      meta: { headerClass: 'rounded-l text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'updatedOn',
      header: 'Last Updated',
      cell: (info) => new Date(info.getValue() as string).toLocaleString(),
      footer: (props) => props.column.id,
      minSize: 70,
      maxSize: 150,
      meta: { headerClass: 'text-left', cellClass: 'text-left' },
    },
    {
      accessorKey: 'actions',
      header: '',
      minSize: 70,
      maxSize: 120,
      cell: (info) => renderCellOfActionBtns(info, openFormPreview, openDeleteFormModal, openCopyFormModal),
      meta: { headerClass: 'rounded-r', cellClass: 'text-center' },
    },
  ];
};
