import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import type { Form } from '../../api/queries/forms/forms.types';
import type { FormPageURLParams } from '../../modules/navigation/utils/getNavigationLink';
import type { FC, ReactElement } from 'react';

import { CopyFormModal } from './components/CopyFormModal/CopyFormModal';
import { DeleteFormModal } from './components/DeleteFormModal/DeleteFormModal';
import { useFormTable } from './hooks/useFormTable';
import { useFormTypeByName } from './hooks/useFormTypeByName';
import { QueryKey } from '../../api/constants';
import { useCreateFormVersion } from '../../api/hooks/useCreateFormVersion';
import { useForms } from '../../api/hooks/useForms';
import { useInfiniteScroll } from '../../common/hooks/useInfiniteScroll';
import { getPermission, PermissionKeys } from '../../common/utils/permissions';
import { Breadcrumb } from '../../components/Breadcrumb/Breadcrumb';
import { CommonTable } from '../../components/CommonTable/CommonTable';
import { Header } from '../../components/Header/Header';
import { Page } from '../../components/Page/Page';
import { useModalState } from '../../modules/form/hooks/useModalState';
import { QuestionEnginePreview } from '../../modules/question-engine';

export const FormPage: FC = (): ReactElement => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { form: formTypeName } = useParams() as FormPageURLParams;

  const {
    open: deleteOpen,
    close: deleteClose,
    isOpen: deleteIsOpen,
    modalData: deleteModalData,
    modalName: deleteModalName,
  } = useModalState<Form>('delete');
  const {
    open: copyOpen,
    close: copyClose,
    isOpen: copyIsOpen,
    modalData: copyModalData,
    modalName: copyModalName,
  } = useModalState<Form>('copy');
  const {
    open: formPreviewOpen,
    close: formPreviewClose,
    isOpen: formPreviewIsOpen,
    modalData: formPreviewModalData,
  } = useModalState<Form>('preview');

  const [nextId, setNextId] = useState<string>('');

  const { formType, isFetching: isFetchingFormType } = useFormTypeByName({ formTypeName });
  const { isFetching, data } = useForms({
    formType: formType?.name,
    enabled: Boolean(formType?.name),
    id: nextId,
    next: Boolean(nextId),
  });

  const { accumulatedData, resetData } = useInfiniteScroll({
    isFetching,
    setNextId,
    data,
  });

  const table = useFormTable({
    formData: accumulatedData,
    openFormPreview: formPreviewOpen,
    openDeleteFormModal: deleteOpen,
    openCopyFormModal: copyOpen,
  });

  const reset = () => {
    resetData();
    void queryClient.invalidateQueries([QueryKey.FETCH_FORMS]);
  };

  const closeDeleteFormModal = () => {
    reset();
    deleteClose();
  };

  const closeCopyFormModal = () => {
    reset();
    copyClose();
  };

  const { mutate, isLoading: isCreationLoading } = useCreateFormVersion({ onSuccess: reset });
  const crumbs = [{ label: 'Forms', path: '/' }, { label: formTypeName }];
  const title = `${formType?.name || ''}`;
  const isLoading = isFetchingFormType || isFetching || isCreationLoading;

  const permission: string = getPermission();

  return (
    <Page id="form" isLoading={isLoading}>
      <Header title={title} />
      <div className="px-8 mt-8">
        <div data-testid="form-table" className="w-full md:w-9/10 lg:w-4/5 xl:w-[1300px] mx-auto flex flex-col gap-y-2">
          <Breadcrumb crumbs={crumbs} />

          <div className="w-full flex">
            <div className="w-full flex sm:justify-start">
              {permission === PermissionKeys.QET_ADMIN && (
                <button
                  className="qe-btn fill"
                  data-testid="deployment-manager-btn"
                  onClick={() => navigate(`/forms/${formTypeName}/deployment-manager`)}
                >
                  DEPLOYMENT MANAGER
                </button>
              )}
            </div>
            <div className="w-full flex sm:justify-end">
              {[PermissionKeys.QET_ADMIN, PermissionKeys.QET_BASIC].includes(permission) && (
                <button className="qe-btn" data-testid="create-new-version-btn" onClick={() => mutate(formTypeName)}>
                  Create New Version
                </button>
              )}
            </div>
          </div>

          <CommonTable table={table} />
        </div>

        {deleteIsOpen && (
          <DeleteFormModal
            isOpen={deleteIsOpen}
            formTypeName={formTypeName}
            formToDelete={deleteModalData}
            onSuccess={closeDeleteFormModal}
            onCancel={deleteClose}
            modalName={deleteModalName}
          />
        )}

        {copyIsOpen && (
          <CopyFormModal
            isOpen={copyIsOpen}
            formTypeName={formTypeName}
            onSuccess={closeCopyFormModal}
            onCancel={copyClose}
            formToCopy={copyModalData}
            modalName={copyModalName}
          />
        )}

        {formPreviewIsOpen && (
          <QuestionEnginePreview
            isOpen={formPreviewIsOpen}
            onClose={formPreviewClose}
            form={formPreviewModalData}
            formTypeName={formType?.name as string}
          />
        )}
      </div>
    </Page>
  );
};
